<template>
  <footer class="py-16 px-6 border-t">
    <div class="container mx-auto flex flex-col gap-16">
      <div class="flex gap-10 flex-wrap justify-between">
        <div class="flex flex-col gap-6 w-full lg:w-fit mb-10 lg:mb-0">
          <branding />
          <div class="flex flex-col gap-2">
            <p class="text-sm opacity-70">
              &copy; 2024 Indonesia Furs. {{ $t("all_rights_reserved") }}.
            </p>
            <p class="text-sm opacity-70">
              Developed by the

              <nuxt-link-locale to="/about-community" class="underline">
                Indonesia Furs DevOps Team</nuxt-link-locale
              >.
            </p>
          </div>
          <!-- <div class="w-1/2 lg:w-full">
            <toolbar-language-switcher />
          </div> -->
        </div>

        <div class="flex gap-10 md:gap-20 flex-wrap">
          <section>
            <h2 class="font-semibold text-base">Resources</h2>
            <ul class="mt-3 space-y-2">
              <li>
                <a href="https://furries.id">Indonesia Furs Website</a>
              </li>
              <li>
                <nuxt-link-locale to="/about-community">
                  About Community
                </nuxt-link-locale>
              </li>
            </ul>
          </section>

          <section>
            <h2 class="font-semibold text-base">Social</h2>
            <ul class="mt-3 space-y-2">
              <li>
                <a href="https://twitter.com/IndonesiaFurs">Twitter</a>
              </li>
              <li>
                <a href="https://www.facebook.com/IndonesianFurries"
                  >Facebook</a
                >
              </li>
              <li>
                <a href="https://t.me/indonesiafurs">Telegram</a>
              </li>
              <li>
                <a href="https://discord.com/invite/R24NWtuW23"
                  >Join our Discord</a
                >
              </li>
            </ul>
          </section>

          <section>
            <h2 class="font-semibold text-base">
              {{ $t("support_title") }}
            </h2>
            <ul class="mt-3 space-y-2">
              <li>
                <nuxt-link-locale to="/help/contact">
                  {{ $t("contact_us_link") }}
                </nuxt-link-locale>
              </li>
              <li>
                <nuxt-link-locale to="/help/terms">
                  {{ $t("terms_of_service_link") }}
                </nuxt-link-locale>
              </li>
              <li>
                <nuxt-link-locale to="/help/privacy-policy">
                  {{ $t("privacy_policy_link") }}
                </nuxt-link-locale>
              </li>
            </ul>
          </section>
        </div>
      </div>

      <!-- Random cheesy quotes -->
      <cheesy-quotes class="text-center opacity-60 text-sm" />
    </div>
  </footer>
</template>
