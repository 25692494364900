<script setup lang="ts">
const quotes = [
  "A bird in the hand is worth two in the bush.",
  "Birds of a feather will gather together.",
  "Birds teach us that borders are just lines drawn on a map.",
  "How fleeting are all human passions compared with the massive continuity of ducks.",
  "I am not a night owl or an early bird, I am an exhausted pigeon.",
  "I've always had a duck personality. Calm above water, feet going crazy below.",
  "If it looks like a duck and sounds like a duck, it could be a really ugly swan.",
  "If you want to fly, you have to give up the things that weigh you down.",
  "Once you have tasted flight, you will forever walk the earth with your eyes turned skyward.",
  "One is never alone with a rubber duck.",
  "Some birds avoid water, the duck searches for it.",
  "Tame birds sing of freedom. Wild birds fly.",
  "The dinosaurs once ruled the word, and one day, the birds might reclaim their rightful place.",
  "The squeaky wheel gets the grease but the quacking duck gets shot.",
  "There are no ugly ducklings.",
  "Where do the ducks go in the winter?",
];

const randomQuote = ref<string>();

onServerPrefetch(async () => {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  randomQuote.value = quotes[randomIndex];
});
</script>

<template>
  <p>{{ randomQuote }}</p>
</template>
