<script setup lang="ts">
const { status } = useSession();
</script>

<template>
  <div class="min-h-screen">
    <a
      href="#main-content"
      class="absolute left-0 top-0 -translate-x-96 bg-yellow-400 p-4 text-lg text-yellow-950 transition-transform focus:block focus:translate-x-0 z-10"
    >
      Skip to main content
    </a>

    <toolbar-without-sidebar class="h-16" />

    <div class="px-6 py-4">
      <slot />
    </div>

    <the-footer class="sticky top-[100vh] mt-20" />
  </div>

  <!-- Onboarding -->
  <onboarding-modal v-if="status === SessionStatus.AUTHENTICATED" />
</template>
