<script setup lang="ts">
import { useStepper } from "@vueuse/core";

const { mutateAsync, isPending } = useUserMutation();
const {
  user: currentUser,
  query: { suspense },
} = useUser();

await suspense();

const showOnboardingModal = computed(() => {
  if (currentUser.value?.has_onboarded === false) {
    return true;
  }

  return false;
});

const userProfileForm = ref({
  displayName: `${currentUser.value?.first_name ?? ""} ${
    currentUser.value?.last_name ?? ""
  }`.trim(),
  avatarUrl: "",
});

const { current, isFirst, isLast, goToNext, goToPrevious, isCurrent } =
  useStepper({
    welcome: {
      isValid: () => true,
    },
    name: {
      isValid: () => userProfileForm.value.displayName.length > 0,
    },
    "profile-picture": {
      isValid: () => true,
    },
    finished: {
      isValid: () => true,
    },
  });

const handleUpdateUserProfile = async () => {
  try {
    await mutateAsync({
      first_name: userProfileForm.value.displayName,
      last_name: "",
    });
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <u-modal
    v-model="showOnboardingModal"
    prevent-close
    :ui="{
      overlay: {
        background: 'bg-gray-200/75 dark:bg-gray-800/75 backdrop-blur-xl',
      },
    }"
  >
    <div class="p-8 flex flex-col gap-6">
      <div v-if="isCurrent('welcome')" class="flex gap-4 flex-col items-center">
        <img src="@/assets/images/if-logo.svg" class="w-16 h-16" />

        <h1 class="text-2xl text-center">Welcome to Community!</h1>

        <p class="opacity-70 text-center">
          Welcome to the home of Indonesia Furs. Here, you can connect with
          other furries, browse events to attend, and more!
        </p>

        <nuxt-img
          src="/images/ej_community.png"
          alt="Community illustration by EJ"
          preload
          sizes="100vw 500px"
        />
      </div>

      <div v-if="isCurrent('name')" class="flex gap-4 flex-col items-center">
        <nuxt-img src="/images/kurari_hello.png" class="w-32 h-32" />

        <h1 class="text-2xl">Hello!</h1>

        <p class="opacity-70 text-center">
          Looks like you're new here!<br />What should we call you?
        </p>

        <u-form-group label="My name is..." class="w-full" required>
          <u-input
            v-model="userProfileForm.displayName"
            placeholder="Dimon Zaenal"
            class="w-full"
            name="Name"
            aria-label="Name"
            maxlength="50"
          />
        </u-form-group>
      </div>

      <div
        v-if="isCurrent('profile-picture')"
        class="flex gap-4 flex-col items-center"
      >
        <nuxt-img src="/images/pepper_camera.png" class="w-32 h-32" />
        <h1 class="text-2xl text-center">Say Cheese!</h1>

        <p class="opacity-70 text-center">
          Nice to meet you, {{ userProfileForm.displayName }}! Now, why don't we
          add a face to your name? Click the avatar below to upload your
          picture.
        </p>

        <user-profile-picture
          :avatar-url="currentUser?.avatar_url"
          :user-full-name="userProfileForm.displayName"
          icon="i-heroicons-camera"
        />
      </div>

      <div
        v-if="isCurrent('finished')"
        class="flex gap-4 flex-col items-center"
      >
        <nuxt-img src="/images/freydag_thumbs_up.png" class="w-32 h-32" />
        <h1 class="text-2xl">Anjay, All Set!</h1>

        <p class="opacity-70 text-center">
          You're all set! Click the button below to start using Community. Don't
          worry, <i>anggap saja rumah sendiri</i>.
        </p>
      </div>

      <div class="flex gap-4 mt-4 self-end">
        <u-button
          v-if="!isFirst"
          icon="i-heroicons-arrow-left"
          variant="ghost"
          @click="goToPrevious"
        >
          Previous
        </u-button>

        <u-button
          v-if="!isLast"
          icon="i-heroicons-arrow-right"
          trailing
          :disabled="!current.isValid()"
          @click="goToNext"
        >
          Next
        </u-button>

        <u-button
          v-if="isLast"
          :loading="isPending"
          icon="i-heroicons-check"
          @click="handleUpdateUserProfile"
        >
          Let's Go!
        </u-button>
      </div>
    </div>
  </u-modal>
</template>
